import _, { merge } from 'lodash';
import { normalize } from 'normalizr';
import { truckListSchema, truckSchema,
         locationSchema, locationListSchema,
         blogPostSchema, blogPostListSchema,
         featuredTruckSchema, featuredTruckListSchema,
         podSchema, podListSchema,
         neighborhoodSchema, neighborhoodListSchema,
         foodCategoryListSchema, foodCategorySchema,
         waitlistEntrySchema, waitlistEntryListSchema } from '../actions/schemas';

const apiUtils = {
  normalize(data) {
    let state = {};

    if (!data) {
      return state;
    }

    if (data.error) {
      state = _.merge({}, state, {
        errors: {
          status: data.error,
          modal: false
        }
      });
    }

    if (data.ui) {
      state = _.merge({}, state, { ui: data.ui });
      state.ui.loading = {};
    }

    if (data.city_config) {
      state = _.merge({}, state, { cityConfig: data.city_config });
    }

    if (data.blog_posts) {
      const blogPosts = normalize(data.blog_posts, blogPostListSchema);
      blogPosts.blogPosts = blogPosts.result;
      delete blogPosts.result;
      state = _.merge({}, state, blogPosts);
    }

    if (data.pagination && data.blog_posts && !data.trucks) {
      state = _.merge({}, state, {
        pagination: {
          blogPost: data.pagination
        }
      });
    }

    if (data.blog_post) {
      const blogPosts = normalize(data.blog_post, blogPostSchema);
      delete blogPosts.result;
      state = _.merge({}, state, blogPosts);
    }

    if (data.trucks) {
      const trucks = normalize(data.trucks, truckListSchema);
      trucks.trucks = trucks.result;
      delete trucks.result;
      state = _.merge({}, state, trucks);
    }

    if (data.pagination && data.trucks) {
      state = _.merge({}, state, {
        pagination: {
          truck: data.pagination
        }
      });
    }

    if (data.truck) {
      const trucks = normalize(data.truck, truckSchema);
      delete trucks.result;
      state = _.merge({}, state, trucks);
    }

    if (data.pods) {
      const pods = normalize(data.pods, podListSchema);
      pods.pods = pods.result;
      delete pods.result;
      state = _.merge({}, state, pods);
    }

    if (data.locations) {
      const locations = normalize(data.locations, locationListSchema);
      locations.locations = locations.result;
      delete locations.result;
      state = _.merge({}, state, locations);
    }

    if (data.location) {
      const locations = normalize(data.location, locationSchema);
      delete locations.result;
      state = _.merge({}, state, locations);
    }

    if (data.neighborhoods) {
      const neighborhoods = normalize(data.neighborhoods, neighborhoodListSchema);
      neighborhoods.neighborhoods = neighborhoods.result;
      delete neighborhoods.result;
      state = _.merge({}, state, neighborhoods);
    }

    if (data.neighborhood) {
      const neighborhoods = normalize(data.neighborhood, neighborhoodSchema);
      delete neighborhoods.result;
      state = _.merge({}, state, neighborhoods);
    }

    if (data.featured_trucks) {
      const featuredTrucks = normalize(data.featured_trucks, featuredTruckListSchema);
      featuredTrucks.featuredTrucks = featuredTrucks.result;
      delete featuredTrucks.result;
      state = _.merge({}, state, featuredTrucks);
    }

    if (data.food_categories) {
      const foodCategories = normalize(data.food_categories, foodCategoryListSchema);
      foodCategories.foodCategories = foodCategories.result;
      delete foodCategories.result;
      state = _.merge({}, state, foodCategories);
    }

    if (data.waitlist_entries) {
      const waitlistEntries = normalize(data.waitlist_entries, waitlistEntryListSchema);
      waitlistEntries.waitlistEntries = waitlistEntries.result;
      delete waitlistEntries.result;
      state = _.merge({}, state, waitlistEntries);
    }

    return state;
  }
};

export default apiUtils;
