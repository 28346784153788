import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { GoogleOAuthProvider } from '@react-oauth/google';

// Misc Requirements
import history from './modules/history';
import getStore from './modules/store';
import IsoMorphUtils from './modules/isoMorphUtils';
import ScrollToTop from './components/shared/ScrollToTop';

let store = null;
let routes = null;

const App = (props) => {
  const { railsContext } = props;
  IsoMorphUtils.serverSide = false;
  IsoMorphUtils.railsContext = railsContext;

  // this.railsContext = railsContext;
  store = getStore(props.data, history, railsContext);

  // Requiring this here so IsoMorphUtils can get server info first
  routes = require('./routes/routes').default;

  return (
    <GoogleOAuthProvider clientId={railsContext.googleCalClientId}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <ScrollToTop>
            {routes}
          </ScrollToTop>
        </ConnectedRouter>
      </Provider>
    </GoogleOAuthProvider>
  );
};

App.propTypes = {
  data: PropTypes.object
};

export default App;
