import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

import React from 'react';

import Client from './Client';
import Server from './Server';
import history from './modules/history';

import * as Sentry from "@sentry/react";

if (!process.env.NODE_ENV === 'development') {
  Sentry.init({
    environment: process.env.NODE_ENV,
    dsn: "https://1aeff8a521a04abc880c7354d7745535@o1194184.ingest.sentry.io/6362490",
    integrations: [
      // Sentry.reactRouterV5BrowserTracingIntegration({ history }),
      Sentry.replayIntegration()
    ],

    tracesSampleRate: 0.05,
    tracePropagationTargets: ["localhost", /^https:\/\/\w+\.seattlefoodtruck\.com\/api/, /^https:\/\/www\.foodcartsportland\.com\/api/],

    // replaysSessionSampleRate: 0.1,
    // replaysOnErrorSampleRate: 1.0,
  });
}

const App = (props) => {

  if (typeof window === `undefined`) {
    return (
      <Server {...props}/>
    );
  }
  return (
    <Client {...props}/>
  );
};

export default App;
