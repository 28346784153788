import PubSub from 'pubsub-js';
import { userLoggedIn, userLoggedOut } from '../actions/authActions';

if (typeof window === 'object') {
  const Auth = require('j-toker/src/j-toker');
  Auth.configure({
    apiUrl: '',
    passwordResetSuccessUrl: () => `${window.location.origin}/change-password`,
    confirmationSuccessUrl: () => `${window.location.origin}/welcome`,
    authProviderPaths: {
      facebook: '/auth/facebook',
      google: '/auth/google_oauth2'
    }
  });
}

let store = null;

PubSub.subscribe('auth', (msg, data) => {
  if (store) {
    if (msg === 'auth.validation.success') {
      store.dispatch(userLoggedIn(data));
    } else if (msg === 'auth.emailRegistration.success') {
      store.dispatch(userLoggedIn(data.data));
    } else if (msg === 'auth.signOut.success') {
      store.dispatch(userLoggedOut());
    } else if (msg === 'auth.validation.error') {
      store.dispatch(userLoggedOut());
    }
  }
});

const authMiddleware = reduxStore => next => action => {
  if (!store) {
    store = reduxStore;
  }
  next(action);
};

export default authMiddleware;
