// Misc Requirements
import baseReducersFor from './baseReducer';

// Create Base Reducers
const baseReducers = baseReducersFor('comments', 'comment');

// Custom Reducers and Base Reducer Overrides
const reducers = (state = {}, action) => {
  switch (action.type) {
    case 'COMMENTS_CREATE_SUCCESS':
      return action.payload.result;

    default:
      return baseReducers(state, action);
  }
};

export default reducers;
