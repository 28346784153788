import { createSelector } from 'reselect';
import _ from 'lodash';

const getEntities = (state) => state.entities;

// Trucks

const getTrucks = (state) => state.trucks;

export const getAllTrucks = createSelector(
  [getTrucks, getEntities],
  (trucks, entities) => {
    if (trucks instanceof Array && entities.truck) {
      return trucks.map((truckId) => entities.truck[truckId]);
    }
    return [];
  }
);

export function getTruck(state, props, idKey = 'id') {
  const { entities } = state;
  const match = props.match || props.computedMatch;
  const params = match.params;
  const id = params[idKey];
  if (id && entities.truck) {
    return entities.truck[id];
  }
  return null;
}

// Featured Trucks

const getFeaturedTrucks = (state) => state.featuredTrucks;

export const getAllFeaturedTrucks = createSelector(
  [getFeaturedTrucks, getEntities],
  (featuredTrucks, entities) => {
    if (featuredTrucks instanceof Array && entities.featuredTruck) {
      return featuredTrucks.map((featuredTruckId) => entities.featuredTruck[featuredTruckId]);
    }
    return [];
  }
);

// Trucks For Select

const getTrucksForSelect = (state) => state.trucksForSelect;

export const getAllTrucksForSelect = createSelector(
  [getTrucksForSelect, getEntities],
  (trucksForSelect, entities) => {
    if (trucksForSelect instanceof Array && entities.truckForSelect) {
      return trucksForSelect.map((truckId) => entities.truckForSelect[truckId]);
    }
    return [];
  }
);


// Locations

const getLocations = (state) => state.locations;

export const getAllLocations = createSelector(
  [getLocations, getEntities],
  (locations, entities) => {
    if (locations instanceof Array) {
      return locations;
    }
    return [];
  }
);

export function getLocation(state, props) {
  const { entities } = state;
  const match = props.match || props.computedMatch;
  const params = match.params;
  const id = params.id;
  if (id && entities.location) {
    return _.find(entities.location, (location) => {
      return location.slug === id;
    });
  }
  return null;
}


// Events

const getEvents = (state) => state.events;

export const getAllEvents = createSelector(
  [getEvents, getEntities],
  (events, entities) => {
    if (events instanceof Array && entities.event) {
      return events.map((eventId) => entities.event[eventId]);
    }
    return [];
  }
);

export function getEvent(state, props, idKey = 'id') {
  const { entities } = state;
  const match = props.match || props.computedMatch;
  const params = match.params;
  const id = Number(params[idKey]);
  if (id && entities.event) {
    return entities.event[id];
  }
  return null;
}

export function getEventById(state, props, id) {
  const { entities } = state;
  if (id && entities.event) {
    return entities.event[id];
  }
  return null;
}

// BlogPosts

const getBlogPosts = (state) => state.blogPosts;

export const getAllBlogPosts = createSelector(
  [getBlogPosts, getEntities],
  (blogPosts, entities) => {
    if (blogPosts instanceof Array && entities.blogPost) {
      return blogPosts.map((blogPostId) => entities.blogPost[blogPostId]);
    }
    return [];
  }
);

export function getBlogPost(state, props) {
  const { entities } = state;
  const match = props.match || props.computedMatch;
  const params = match.params;
  const id = params.id;
  if (id && entities.blogPost) {
    return entities.blogPost[id];
  }
  return null;
}


// Pods

const getPods = (state) => state.pods;

export const getAllPods = createSelector(
  [getPods, getEntities],
  (pods, entities) => {
    if (pods instanceof Array && entities.pod) {
      return pods.map((podId) => entities.pod[podId]);
    }
    return [];
  }
);

export function getPod(state, props) {
  const { entities } = state;
  const match = props.match || props.computedMatch;
  const params = match.params;
  const id = params.id;
  if (id && entities.pod) {
    return entities.pod[id];
  }
  return null;
}

// Notifications

const getNotifications = (state) => state.notifications;

export const getAllNotifications = createSelector(
  [getNotifications, getEntities],
  (notifications, entities) => {
    if (notifications instanceof Array && entities.notification) {
      return notifications.map((notificationId) => entities.notification[notificationId]);
    }
    return [];
  }
);

export function getNotification(state, props) {
  const { entities } = state;
  const match = props.match || props.computedMatch;
  const params = match.params;
  const id = params.id;
  if (id && entities.notification) {
    return entities.notification[id];
  }
  return null;
}

// Plans

const getPlans = (state) => state.plans;

export const getAllPlans = createSelector(
  [getPlans, getEntities],
  (plans, entities) => {
    if (plans instanceof Array && entities.plan) {
      return plans.map((planId) => entities.plan[planId]);
    }
    return [];
  }
);

export function getPlan(state, props) {
  const { entities } = state;
  const match = props.match || props.computedMatch;
  const params = match.params;
  const id = params.id;
  if (id && entities.plan) {
    return entities.plan[id];
  }
  return null;
}


// Neighborhoods

const getNeighborhoods = (state) => state.neighborhoods;

export const getAllNeighborhoods = createSelector(
  [getNeighborhoods, getEntities],
  (neighborhoods, entities) => {
    if (neighborhoods instanceof Array && entities.neighborhood) {
      return neighborhoods.map((neighborhoodId) => entities.neighborhood[neighborhoodId]);
    }
    return [];
  }
);

export function getNeighborhood(state, props) {
  const { entities } = state;
  const match = props.match || props.computedMatch;

  if (match) {
    const params = match.params;
    const id = params.id;
    if (id && entities.neighborhood) {
      return entities.neighborhood[id];
    }
  }

  return null;
}

// Food Categories

const getFoodCategories = (state) => state.foodCategories;

export const getAllFoodCategories = createSelector(
  [getFoodCategories, getEntities],
  (foodCategories, entities) => {
    if (foodCategories instanceof Array && entities.foodCategory) {
      return foodCategories.map((foodCategoryId) => entities.foodCategory[foodCategoryId]);
    }
    return [];
  }
);

export function getFoodCategory(state, props) {
  const { entities } = state;
  const match = props.match || props.computedMatch;
  const params = match.params;
  const id = params.id;
  if (id && entities.foodCategory) {
    return entities.foodCategory[id];
  }
  return null;
}


// Catering Requests

const getCateringRequests = (state) => state.cateringRequests;

export const getAllCateringRequests = createSelector(
  [getCateringRequests, getEntities],
  (cateringRequests, entities) => {
    if (cateringRequests instanceof Array && entities.cateringRequest) {
      return cateringRequests.map((cateringRequestId) =>
        entities.cateringRequest[cateringRequestId]);
    }
    return [];
  }
);

export function getCateringRequest(state, props) {
  const { entities } = state;
  const match = props.match || props.computedMatch;
  const params = match.params;
  const id = params.request_id;
  if (id && entities.cateringRequest) {
    return entities.cateringRequest[id];
  }
  return null;
}

// Pagination

export function getPaginationForEntity(state, entity) {
  const { pagination } = state;
  if (pagination[entity]) {
    return pagination[entity];
  }
  return null;
}


// Users

const getUsers = (state) => state.users;

export const getAllUsers = createSelector(
  [getUsers, getEntities],
  (users, entities) => {
    if (users instanceof Array && entities.user) {
      return users.map((userId) => entities.user[userId]);
    }
    return [];
  }
);

export function getUser(state, props) {
  const { entities } = state;
  const match = props.match || props.computedMatch;
  const params = match.params;
  const id = params.id;
  if (id && entities.user) {
    return entities.user[id];
  }
  return null;
}

export function getCurrentUser(state) {
  const { entities, auth } = state;
  const id = auth.userData.id;
  if (id && entities.user) {
    return entities.user[id];
  }
  return null;
}


// Subscriptions

const getSubscriptions = (state) => state.subscriptions;

export const getAllSubscriptions = createSelector(
  [getSubscriptions, getEntities],
  (subscriptions, entities) => {
    if (subscriptions instanceof Array && entities.subscription) {
      return subscriptions.map((subscriptionId) => entities.subscription[subscriptionId]);
    }
    return [];
  }
);

export function getSubscription(state, props) {
  const { entities } = state;
  const match = props.match || props.computedMatch;
  const params = match.params;
  const id = params.id;
  if (id && entities.subscription) {
    return entities.subscription[id];
  }
  return null;
}


// Comments

const getComments = (state) => state.comments;

export const getCommentsForBid = createSelector(
  [getComments, getEntities],
  (comments, entities) => {
    if (comments instanceof Array && entities.comment) {
      return comments.map((commentId) => entities.comment[commentId]);
    }
    return [];
  }
);


// Waitlist Entries

const getWaitlistEntries = (state) => state.waitlistEntries;

export const getAllWaitlistEntries = createSelector(
  [getWaitlistEntries, getEntities],
  (waitlistEntries, entities) => {
    if (waitlistEntries instanceof Array && entities.waitlistEntry) {
      return waitlistEntries.map((waitlistEntryId) => entities.waitlistEntry[waitlistEntryId]);
    }
    return [];
  }
);


// Messages

const getMessages = (state) => state.messages;

export const getAllMessages = createSelector(
  [getMessages, getEntities],
  (messages, entities) => {
    if (messages instanceof Array && entities.message) {
      return messages.map((messageId) => entities.message[messageId]);
    }
    return [];
  }
);


// Revenue Months

const getRevenueMonths = (state) => state.revenueMonths;

export const getAllRevenueMonths = createSelector(
  [getRevenueMonths, getEntities],
  (revenueMonths, entities) => {
    if (revenueMonths instanceof Array && entities.revenueMonth) {
      return revenueMonths.map((revenueMonthId) => entities.revenueMonth[revenueMonthId]);
    }
    return [];
  }
);


// Instagram

export const getInstagramPosts = (state) => state.instagramPosts;


// City Config

export const getCityConfig = (state) => state.cityConfig;
