import { push } from 'connected-react-router';

export const SHOW_NOTICE = 'SHOW_NOTICE';
export const HIDE_NOTICE = 'HIDE_NOTICE';
export const CLEAR_NOTICE = 'CLEAR_NOTICE';
export const REDIRECT_TO_NOTICE = 'REDIRECT_TO_NOTICE';
export const SEEN_NOTICE = 'SEEN_NOTICE';

export function showNotice(message) {
  return {
    type: SHOW_NOTICE,
    message
  };
}

export function hideNotice() {
  return {
    type: HIDE_NOTICE
  };
}

export function clearNotice() {
  return {
    type: CLEAR_NOTICE
  };
}

export function createNotice(message) {
  return dispatch => {
    dispatch(showNotice(message));

    setTimeout(() => {
      dispatch(hideNotice());
    }, 2000);

    setTimeout(() => {
      dispatch(clearNotice());
    }, 3000);
  };
}

export function redirectToNotice(headline, message, returnUrl = '/') {
  return {
    type: REDIRECT_TO_NOTICE,
    headline,
    message,
    returnUrl
  };
}

export function clearAndGo(path) {
  return dispatch => {
    dispatch(push(path));
    dispatch(clearNotice());
  };
}

export function seenNotice() {
  return {
    type: SEEN_NOTICE
  };
}
