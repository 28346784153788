const snakeCase = require('lodash/snakeCase');
const trim = require('lodash/trim');

function addGroup(resource, actionTypes, group, config) {
  const upperResource = snakeCase(resource).toUpperCase();
  const upperGroup = group.toUpperCase();

  const start = `${upperResource}_${upperGroup}_START`;
  const success = `${upperResource}_${upperGroup}_SUCCESS`;
  const error = `${upperResource}_${upperGroup}_ERROR`;
  const startAlias = `${group}Start`;
  const successAlias = `${group}Success`;
  const errorAlias = `${group}Error`;

  actionTypes[start] = start;
  actionTypes[success] = success;
  actionTypes[error] = error;

  if (config.addAlias) {
    actionTypes[startAlias] = start;
    actionTypes[successAlias] = success;
    actionTypes[errorAlias] = error;
  }
}

export default function (resource, config) {
  if (resource == null) throw new Error('Expected resource');
  config = config || {};
  if (config.addAlias == null) config.addAlias = true;

  resource = trim(resource);
  if (resource === '') throw new Error('Expected resource');
  const actionTypes = {};

  addGroup(resource, actionTypes, 'fetch', config);
  addGroup(resource, actionTypes, 'fetchOne', config);
  addGroup(resource, actionTypes, 'create', config);
  addGroup(resource, actionTypes, 'update', config);
  addGroup(resource, actionTypes, 'delete', config);

  const clear = `${snakeCase(resource).toUpperCase()}_CLEAR`;
  actionTypes[clear] = clear;
  actionTypes.clear = clear;

  return actionTypes;
}
