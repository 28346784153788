import { schema } from 'normalizr';

export const truckSchema = new schema.Entity('truck');
export const truckListSchema = [truckSchema];

export const featuredTruckSchema = new schema.Entity('featuredTruck');
export const featuredTruckListSchema = [featuredTruckSchema];

export const truckForSelectSchema = new schema.Entity('truckForSelect');
export const truckForSelectListSchema = [truckForSelectSchema];

export const locationSchema = new schema.Entity('location');
export const locationListSchema = [locationSchema];

export const eventSchema = new schema.Entity('event');
export const eventListSchema = [eventSchema];

export const podSchema = new schema.Entity('pod');
export const podListSchema = [podSchema];

export const notificationSchema = new schema.Entity('notification');
export const notificationListSchema = [notificationSchema];

export const commentSchema = new schema.Entity('comment');
export const commentListSchema = [commentSchema];

export const planSchema = new schema.Entity('plan');
export const planListSchema = [planSchema];

export const userSchema = new schema.Entity('user');
export const userListSchema = [userSchema];

export const subscriptionSchema = new schema.Entity('subscription');
export const subscriptionListSchema = [subscriptionSchema];

export const neighborhoodSchema = new schema.Entity('neighborhood');
export const neighborhoodListSchema = [neighborhoodSchema];

export const foodCategorySchema = new schema.Entity('foodCategory');
export const foodCategoryListSchema = [foodCategorySchema];

export const waitlistEntrySchema = new schema.Entity('waitlistEntry');
export const waitlistEntryListSchema = [waitlistEntrySchema];

export const blogPostSchema = new schema.Entity('blogPost');
export const blogPostListSchema = [blogPostSchema];

export const cateringRequestSchema = new schema.Entity('cateringRequest');
export const cateringRequestListSchema = [cateringRequestSchema];

export const messageSchema = new schema.Entity('message');
export const messageListSchema = [messageSchema];

export const revenueMonthSchema = new schema.Entity('revenueMonth');
export const revenueMonthListSchema = [revenueMonthSchema];