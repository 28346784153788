import {
  SHOW_NOTICE,
  HIDE_NOTICE,
  CLEAR_NOTICE,
  SEEN_NOTICE,
  REDIRECT_TO_NOTICE
} from '../actions/noticeActions';

const initialState = {
  status: 'hidden',
  seenNotice: false,
  message: null,
  headline: null,
  returnUrl: null,
  type: '',
  redirect: false
};

const notice = (state = initialState, action) => {
  switch (action.type) {

    case SHOW_NOTICE:
      return Object.assign({}, state, {
        status: 'visible',
        message: action.message
      });

    case HIDE_NOTICE:
      return Object.assign({}, state, {
        status: 'hidden',
      });

    case SEEN_NOTICE:
      return Object.assign({}, state, {
        seenNotice: true,
      });

    case CLEAR_NOTICE:
      return Object.assign({}, state, {
        message: null,
        headline: null,
        returnUrl: null,
        redirect: false,
        seenNotice: false
      });

    case REDIRECT_TO_NOTICE:
      return Object.assign({}, state, {
        message: action.message,
        headline: action.headline,
        returnUrl: action.returnUrl,
        redirect: true
      });

    default:
      return state;
  }
};

export default notice;
