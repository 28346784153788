import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { StaticRouter } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';

// Misc Requirements
import history from './modules/history';
import getStore from './modules/store';
import IsoMorphUtils from './modules/isoMorphUtils';

let store = null;

const App = (props) => {
  const { data, railsContext } = props;
  const { location } = railsContext;
  IsoMorphUtils.railsContext = railsContext;
  IsoMorphUtils.serverSide = true;
  store = getStore(data, history, railsContext);

  // Requiring this here so IsoMorphUtils can get server info first
  const routes = require('./routes/routes').default;

  return (
    <GoogleOAuthProvider clientId={railsContext.googleCalClientId}>
      <Provider store={store}>
        <StaticRouter location={location} context={{}}>
          {routes}
        </StaticRouter>
      </Provider>
    </GoogleOAuthProvider>
  );
};

App.propTypes = {
  data: PropTypes.object
};

export default App;