import _ from 'lodash';

import {
  RESET_SELECTED_PAGE
} from '../actions/paginationActions';

const paginationReducer = (state = {}, action) => {
  const payload = action.payload;

  if (payload && payload.pagination) {
    return _.merge({}, state, payload.pagination);
  }

  if (_.includes(action.type, 'CLEAR') &&
      action.type !== 'CLEAR_SERVER_RENDERED_PATH') {
    const newState = Object.assign({}, state);
    newState[action.entity] = {};
    return newState;
  }

  switch (action.type) {
    case RESET_SELECTED_PAGE: {
      const newState = Object.assign({}, state);
      newState[action.entity].selectedPage = 1;
      return newState;
    }

    default:
      return state;
  }
};

export default paginationReducer;
