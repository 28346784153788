// Misc Requirements
import baseReducersFor from './baseReducer';

// Create Base Reducers
const baseReducers = baseReducersFor('subscriptions', 'subscription');

// Custom Reducers and Base Reducer Overrides
const reducers = (state = {}, action) => {
  switch (action.type) {
    default:
      return baseReducers(state, action);
  }
};

export default reducers;
