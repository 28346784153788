import {
  DISPLAY_ERROR,
  CLEAR_ERROR,
} from '../actions/errorActions';

const initialState = {
};

const errorsReducer = (state = initialState, action) => {

  switch (action.type) {
    case DISPLAY_ERROR:
      return Object.assign({}, state, {
        status: action.status,
        message: action.message,
        modal: action.modal
      });

    case CLEAR_ERROR:
      return Object.assign({}, state, {
        status: null,
        message: null,
        modal: null
      });

    default:
      return state;
  }
};

export default errorsReducer;
