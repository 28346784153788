// NPM Requirements
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as formReducer } from 'redux-form';

// Reducers
import notices from './noticeReducer';
import trucks from './trucksReducer';
import featuredTrucks from './featuredTrucksReducer';
import trucksForSelect from './trucksForSelectReducer';
import locations from './locationsReducer';
import events from './eventsReducer';
import pods from './podsReducer';
import notifications from './notificationsReducer';
import comments from './commentsReducer';
import plans from './plansReducer';
import users from './usersReducer';
import subscriptions from './subscriptionsReducer';
import neighborhoods from './neighborhoodsReducer';
import foodCategories from './foodCategoriesReducer';
import waitlistEntries from './waitlistEntriesReducer';
import messages from './messagesReducer';
import revenueMonths from './revenueMonthsReducer';
import cateringRequests from './cateringRequestsReducer';
import blogPosts from './blogPostsReducer';
import auth from './authReducer';
import entities from './entitiesReducer';
import pagination from './paginationReducer';
import ui from './uiReducer';
import errors from './errorsReducer';
import instagramPosts from './instagramReducer';
import cityConfig from './cityConfigReducer';

const rootReducer = (history) => {
  return combineReducers({
    router: connectRouter(history),
    entities,
    trucks,
    featuredTrucks,
    trucksForSelect,
    locations,
    events,
    pods,
    notifications,
    comments,
    plans,
    users,
    subscriptions,
    neighborhoods,
    foodCategories,
    waitlistEntries,
    cateringRequests,
    blogPosts,
    messages,
    revenueMonths,
    notices,
    auth,
    ui,
    errors,
    pagination,
    form: formReducer,
    instagramPosts,
    cityConfig
  });
}

export default rootReducer;
