// Misc Requirements
import baseReducersFor from './baseReducer';
import _ from 'lodash';

// Create Base Reducers
const baseReducers = baseReducersFor('events', 'event');

// Custom Reducers and Base Reducer Overrides
const reducers = (state = [], action) => {
  switch (action.type) {

    case 'EVENT_DELETE_SUCCESS':
      return _.filter(state, (id) => {
        return !_.includes(action.deletedOccurrences, id)
      });

    default:
      return baseReducers(state, action);
  }
};

export default reducers;
