import { createBrowserHistory } from 'history';

let history = { location: null };

if (typeof window === 'object') {
  history = createBrowserHistory();
}

function getHistory() {
  return history;
}

export default getHistory();