// Misc Requirements
import baseReducersFor from './baseReducer';

// Create Base Reducers
const baseReducers = baseReducersFor('locations', 'location');

// Custom Reducers and Base Reducer Overrides
const reducers = (state = {}, action) => {
  switch (action.type) {

    case 'ACTION':
      return state;

    case 'LOCATIONS_FETCH_SUCCESS':
      let locationList = [];
      action.payload.result.forEach((id) => {
        locationList.push(action.payload.entities.location[id]);
      });

      return locationList;
    default:
      return baseReducers(state, action);
  }
};

export default reducers;
