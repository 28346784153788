import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import rootReducer from '../reducers/rootReducer';
import authMiddleware from '../modules/authMiddleware';
import apiUtils from '../modules/apiUtils';
import * as Sentry from "@sentry/react";

let store = null;

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Options
});

function getStore(props, history, railsContext) {
  const initialState = apiUtils.normalize(props.data);
  const store = createStore(
    rootReducer(history),
    initialState,
    compose(
      applyMiddleware(routerMiddleware(history)),
      applyMiddleware(thunkMiddleware),
      applyMiddleware(authMiddleware),
      typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION__ ?
        window.__REDUX_DEVTOOLS_EXTENSION__() : f => f,
      sentryReduxEnhancer,
    ),
  );
  return store;
}

if (module.hot) {
  module.hot.accept('../reducers/rootReducer', () => {
    store.replaceReducer(require('../reducers/rootReducer').default);
  });
}

export default getStore;
