const IsoMorphUtils = {
  railsContext: null,
  serverSide: false,
  error500: false,

  ready(props) {
    if (this.serverSide === true) return true;
    if (this.serverRendered(props)) return true;
    return false;
  },

  serverRendered(props) {
    const { ui, location } = props;
    if (ui && location) {
      return ui.serverRenderedPath === location.pathname;
    }
    return false;
  }
};

export default IsoMorphUtils;
