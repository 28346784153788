export const DISPLAY_ERROR = 'DISPLAY_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';

export function displayError(status, message, modal = false) {
  return {
    type: DISPLAY_ERROR,
    status,
    message,
    modal
  };
}

export function clearError() {
  return {
    type: CLEAR_ERROR
  };
}
