import _ from 'lodash';

import {
  CLEAR_SERVER_RENDERED_PATH,
} from '../actions/uiActions';

const initialState = {
  serverRenderedPath: null,
  loading: {}
};

const ENTITIES = ['USER', 'TRUCK', 'POD', 'LOCATION', 'NEIGHBORHOOD', 'NOTIFICATION',
  'COMMENT', 'CATERING', 'EVENT', 'FOOD', 'BLOG', 'PLAN', 'SUBSCRIPTION'];

const IGNORED_ENTITIES = ['FOR_SELECT'];

const START_FETCH_ACTIONS = ['FETCH_START', 'FETCHONE_START'];
const END_FETCH_ACTIONS = ['FETCH_SUCCESS', 'FETCHONE_SUCCESS'];

const START_UPDATE_ACTIONS = [
  'UPDATE_START', 'CREATE_START',
  'DELETE_START', 'REGISTERING'
];
const END_UPDATE_ACTIONS = [
  'UPDATE_SUCCESS', 'CREATE_SUCCESS',
  'DELETE_SUCCESS', 'REGISTERED', 'FAILED'
];

const loadingReducer = (state, action) => {
  const type = action.type;
  let startFetchAction = null;
  let startUpdateAction = null;
  let endFetchAction = null;
  let endUpdateAction = null;
  let loading = false;

  if (type === 'DISPLAY_ERROR') {
    const newState = _.merge({}, state);
    _.forIn(state.loading, (value, key) => {
      newState.loading[key] = false;
    });
    return newState;
  }

  const ignoredEntity = _.find(IGNORED_ENTITIES, (e) => {
    return _.includes(type, e);
  });

  if (ignoredEntity) return state;

  const entity = _.find(ENTITIES, (e) => {
    return _.includes(type, e);
  });

  startFetchAction = _.find(START_FETCH_ACTIONS, (e) => {
    return _.includes(type, e);
  });

  endFetchAction = _.find(END_FETCH_ACTIONS, (e) => {
    return _.includes(type, e);
  });

  startUpdateAction = _.find(START_UPDATE_ACTIONS, (e) => {
    return _.includes(type, e);
  });

  endUpdateAction = _.find(END_UPDATE_ACTIONS, (e) => {
    return _.includes(type, e);
  });

  if (entity && (startFetchAction || endFetchAction)) {
    if (startFetchAction) {
      loading = true;
    }
    const fetchOne = _.includes(type, 'ONE');
    const newState = _.merge({}, state);

    newState.loading[`${_.toLower(entity)}Fetch`] = loading;

    if (fetchOne) {
      newState.loading[`${_.toLower(entity)}FetchOne`] = loading;
    } else {
      newState.loading[`${_.toLower(entity)}FetchIndex`] = loading;
    }

    return newState;
  }

  if (entity && (startUpdateAction || endUpdateAction)) {
    if (startUpdateAction) {
      loading = true;
    }
    const newState = _.merge({}, state);
    newState.loading[`${_.toLower(entity)}Update`] = loading;
    return newState;
  }
  return state;
};

const uiReducer = (state = initialState, action) => {
  switch (action.type) {

    case CLEAR_SERVER_RENDERED_PATH:
      return Object.assign({}, state, {
        serverRenderedPath: null
      });

    default:
      return loadingReducer(state, action);
  }
};

export default uiReducer;
