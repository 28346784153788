import _ from 'lodash';

const entitiesReducer = (state = {}, action) => {
  const payload = action.payload;

  if (payload && payload.entities) {
    const newState = _.merge({}, state);

    // Update just the child items
    if (action.single) {
      _.forOwn(payload.entities, (entityValue, entityKey) => {
        _.forOwn(entityValue, (itemValue, itemKey) => {
          if (!newState[entityKey]) {
            newState[entityKey] = {};
          }
          newState[entityKey][itemKey] = itemValue;
        });
      });
      return newState;
    }

    // Update all the parent entities
    _.forOwn(payload.entities, (value, key) => {
      // newState[key] = value;
      newState[key] = _.merge(newState[key], value);
    });

    return newState;
  }

  if (action.type === 'NOTIFICATION_CLEAR_START') {
    const newState = _.merge({}, state);
    let cateringRequest = null;

    // For Vendors
    cateringRequest = _.find(newState.cateringRequest, { bid_id: action.data.bid_id });

    // For Customers
    if (!cateringRequest) {
      const bids = _.flatten(_.map(newState.cateringRequest, _.iteratee('bids')));
      if (bids) {
        cateringRequest = _.find(bids, { id: action.data.bid_id });
      }
    }

    if (cateringRequest) {
      cateringRequest.notification_count = 0;
    }
    return newState;
  }

  return state;
};

export default entitiesReducer;
