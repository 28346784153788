// See React on Rails Pro documentation for details regarding **.imports-X.** file extension & duplicate file structure.
import loadable from '@loadable/component';
import retry from '../utils/retry';

// Routes
export const Layout = loadable(() => retry(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "Layout" */
  './Layout')));
export const AdminLayout = loadable(() => retry(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "AdminLayout" */
  './AdminLayout')));
export const BoeingLayout = loadable(() => retry(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "BoeingLayout" */
  './boeing/BoeingLayout')));